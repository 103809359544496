import { useEffect, useState } from "react";
import { SingleFormProduct } from "../types";
import { CartState } from "@base-library/enums";
import {
  CartForm,
  IDictionary,
  PartialProduct,
} from "@base-library/types";
import {
  BaseFormField,
  MaskFormField,
  NumericFormField,
  MultipleOptionsFormField,
} from "@base-library/fields";

type State = {
  cartState: CartState;
  products: PartialProduct[];
  cartForms: CartForm[];
  contactForm?: Fields;
  singleFormProduct?: SingleFormProduct;
};

enum ResultType {
  CartForms = 0,
  Products = 1,
  Forms = 2,
  Empty = 3,
}

type Fields = IDictionary<
  BaseFormField | MaskFormField | NumericFormField | MultipleOptionsFormField
>;

type Result = {
  type: ResultType;
  data: unknown;
  contact_forms: Fields;
};

export default function useCartState(
  sendMessage: (message: any) => void,
  productId?: string,
  childIndex?: string
) {
  const [state, setState] = useState<State>({
    cartState: CartState.Processing,
    products: [],
    cartForms: [],
  });

  function resetState() {
    setState({
      cartState: CartState.Empty,
      cartForms: [],
      products: [],
    });
  }

  useEffect(() => {
    updateCart().then();
  }, [productId, childIndex]);

  async function updateCart() {
    if (productId) {
      const resp = await fetch(
        `/api/products/forms/${productId}/${childIndex ?? ""}`
      );

      if (!resp.ok) window.location.pathname = productId;

      setState({
        ...state,
        cartState: CartState.CheckoutForms,
        singleFormProduct: (await resp.json()) as SingleFormProduct,
      });

      return;
    }

    sendMessage("update");

    const id = sessionStorage.getItem("cart") || "";
    const resp = await fetch(`/api/cart/${id}`, {
      method: "GET",
    });

    if (resp.status >= 400) {
      resetState();

      return;
    }

    const data = await resp.json();

    const result = data as Result;

    const contactForm =
      (result.type !== ResultType.Empty && result.contact_forms) || {};

    if (result.type === ResultType.CartForms) {
      setState({
        ...state,
        cartState: CartState.CheckoutForms,
        products: state.products.length > 0 ? [] : state.products,
        cartForms: result.data as CartForm[],
        contactForm: contactForm,
      });
    } else if (result.type === ResultType.Products) {
      const productDict = result.data as IDictionary<PartialProduct[]>;
      setState({
        ...state,
        cartState: CartState.Checkout,
        products: Object.values(productDict).flatMap((x) => x),
        cartForms: state.cartForms.length > 0 ? [] : state.cartForms,
        contactForm: contactForm,
      });
    } else if (result.type === ResultType.Forms) {
      setState({
        ...state,
        cartState: CartState.CheckoutForms,
        contactForm: contactForm,
      });
    } else {
      setState({
        ...state,
        cartState: CartState.Empty,
        contactForm: contactForm,
      });
    }
  }

  return {
    updateCart,
    products: state.products,
    cartForms: state.cartForms,
    singleFormProduct: state.singleFormProduct,
    contactForm: state.contactForm,
    status: state.cartState,
  };
}
