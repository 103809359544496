import { ReactNode } from "react";
import { AddonFieldType, IDictionary, ProductAddon } from "@base-library/types";
import AddonDropdown from "./AddonDropdown";
import { IAddonResult, MultiAddonResult } from "@base-library/fields";

interface Props {
  addonResults: IDictionary<IAddonResult>;
  addons: ProductAddon[];
  index: number;
  dropdownIndex?: number;
}

function calculateAddonCount(
  addons: ProductAddon[],
  selectedAddons: IDictionary<IAddonResult>
) {
  if (!selectedAddons) return 0;
  let count = Object.values(selectedAddons).length;

  for (const addonId of Object.keys(selectedAddons)) {
    const addon = addons.find((x) => x.id === addonId);
    if (!addon) continue;

    const result = selectedAddons[addonId];

    if (addon.field_type === AddonFieldType.Checkbox) {
      const checkboxResult = result as MultiAddonResult;

      count += checkboxResult.value.length - 1;
    }
  }

  return count;
}

export function Addons({
  addons,
  addonResults,
  index,
  dropdownIndex,
}: Props): ReactNode {
  if (!addons) return;

  const length = calculateAddonCount(addons, addonResults);
  if (length === 0) return;
  return (
    <section className="relative justify-center items-center py-4 text-sm font-semibold text-gray-900 rounded-br-lg group has-tooltip">
      <p className="mt-1 text-sm text-gray-500">
        +{length} addon
        {length > 1 ? "s" : ""}
      </p>
      <span className="sr-only">Addons</span>

      <AddonDropdown
        index={index}
        selectedIndex={dropdownIndex}
        addons={addons}
        results={addonResults}
      />
    </section>
  );
}
