import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import App from "./App.tsx";
import r2wc from "@r2wc/react-to-web-component";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://2f1d6e876777fa5bc24c7bf510f35170@o4508391244365824.ingest.us.sentry.io/4508391246397440",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/brownmagician\.com\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const queryClient = new QueryClient();

interface Props {
  productid: string;
  childindex?: string;
  selectedAddons?: string;
}

function Checkout({ productid, childindex, selectedAddons }: Props) {
  return (
    // Provide the client to your App
    <QueryClientProvider client={queryClient}>
      <App
        productId={productid}
        childIndex={childindex}
        selectedAddons={selectedAddons}
      />
    </QueryClientProvider>
  );
}

const CustomElement = r2wc(Checkout, {
  props: {
    productid: "string",
    childindex: "string",
    selectedAddons: "string",
  },
});

customElements.define("checkout-form", CustomElement);
