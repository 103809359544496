import { ReactNode, useState } from "react";
import { CartForm, PartialProduct, IDictionary } from "@base-library/types";
import useCartItems from "../hooks/useCartItems";
import { Addons } from "./Addons";
import CartItemsFooter from "./CartItemsFooter";
import { IAddonResult } from "@base-library/fields";

interface Props {
  cartForms: CartForm[];
  products: PartialProduct[];
  onUpdate?: (id: string) => Promise<void> | void;
}

export default function CartImages({
  cartForms,
  products,
  onUpdate,
}: Props): ReactNode {
  const [removeItem, add, subtract] = useCartItems(onUpdate);
  const [dropdownIndex, setDropdownIndex] = useState<number>();

  const data =
    cartForms.length > 0 ? cartForms.map((x) => x.product) : products;

  const group = data?.reduce(
    (group: { [key: string]: PartialProduct[] }, item) => {
      if (!group[item.item_id]) group[item.item_id] = [];

      group[item.item_id].push(item);
      return group;
    },
    {}
  );

  const values = Object.values(group);

  if (!group) return;

  return values.map((group, index) => {
    const product = group[0];
    const selectedAddons: IDictionary<IAddonResult> =
      product.selected_addons || {};

    return (
      <section
        className="flex rounded-md p-2 hover:bg-gray-900/50 hover:cursor-pointer"
        key={product.item_id}
        onClick={() => {
          if (dropdownIndex !== index) setDropdownIndex(index);
          else setDropdownIndex(undefined);
        }}
      >
        <article className="overflow-hidden flex-shrink-0 w-24 h-full rounded-md">
          <img
            src={product.image_thumbnail}
            className="object-scale-down w-full rounded lg:aspect-none"
          />
        </article>

        <article className="flex flex-col flex-1 ml-4">
          <section>
            <article className="flex justify-between text-base font-medium text-white">
              <h3>{product.name}</h3>

              <section className="flex">
                <button
                  type="button"
                  className="font-medium bi bi-trash-fill text-red-600 hover:text-red-500"
                  onClick={(e) => {
                    e.stopPropagation();
                    removeItem(product);
                  }}
                ></button>
              </section>
            </article>
            <p className="mt-1 text-sm text-gray-500">
              {product.short_description}
            </p>

            <Addons
              index={index}
              dropdownIndex={dropdownIndex}
              addons={product.addons!}
              addonResults={selectedAddons}
            />
          </section>

          <CartItemsFooter
            add={add}
            group={group}
            product={product}
            subtract={subtract}
          />
        </article>
      </section>
    );
  });
}
