import useCartChannel from "@base-library/hooks/useCartChannel";
import { CartState } from "@base-library/enums";
import Cart from "./components/Cart";
import SinglePageCheckout from "./components/SinglePageCheckout";
import Spinner from "@base-library/components/Spinner";
import useCartState from "./hooks/useCartState";

interface Props {
  productId?: string;
  childIndex?: string;
  selectedAddons?: string;
}

function App({ productId, childIndex, selectedAddons }: Props) {
  const { sendMessage } = useCartChannel(() => {});
  const {
    status,
    cartForms,
    contactForm,
    products,
    updateCart,
    singleFormProduct,
  } = useCartState(sendMessage, productId, childIndex);

  if (status === CartState.Processing)
    return (
      <section className="flex grow items-center justify-center text-center text-white">
        <Spinner />
      </section>
    );

  if (singleFormProduct)
    return <SinglePageCheckout singleFormProduct={singleFormProduct} selectedAddons={selectedAddons} />;

  if (status === CartState.Empty)
    return (
      <p className="self-center w-full text-center text-white">Cart is empty</p>
    );

  return (
    <Cart
      cartForms={cartForms}
      products={products}
      hasForms={status === CartState.CheckoutForms}
      onUpdate={updateCart}
      contact_form={contactForm!}
    />
  );
}

export default App;
