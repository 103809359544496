import { Fragment, useState } from "react";
import { FormFieldType } from "@base-library/enums";
import {
  BaseFormField,
  IFormFieldResult,
  MaskFormField,
  MultipleOptionsFormField,
  NumericFormField,
} from "@base-library/fields";
import { FormValues, IDictionary } from "@base-library/types";
import { FormField } from "@base-library/components/forms/FormField";
import { FormFieldChildren } from "@base-library/components/FormFieldChildren";

interface Props {
  onUpdate: (fieldName: string, value: string) => Promise<void> | void;
  contact_form: IDictionary<
    BaseFormField | MaskFormField | NumericFormField | MultipleOptionsFormField
  >;
  storedValues?: FormValues | undefined;
}

export default function ContactForm({
  onUpdate,
  contact_form,
  storedValues,
}: Props): React.ReactNode {
  const [values, setValues] = useState<IDictionary<IFormFieldResult>>({});

  function valueChanged(result: IFormFieldResult, stringResult?: string) {
    setValues({
      ...values,
      [result.id]: result,
    });

    if (onUpdate) onUpdate(result.name, stringResult!);
  }

  const sortedValues = Object.values(contact_form).sort(
    (a, b) => a.order - b.order
  );

  return (
    <>
      {sortedValues.map((field) => {
        if (
          field.type === FormFieldType.MultiSelect ||
          field.type === FormFieldType.Select ||
          field.type === FormFieldType.RadioButton
        ) {
          const selectOption = field as MultipleOptionsFormField;
          const selectValues = selectOption.options;

          if (!selectOption.multi_form) {
            return (
              <FormField
                key={`contact_${field.id}`}
                id={`contact_${field.id}`}
                name={field.id}
                fieldType={field.type}
                label={field.name}
                placeholder={field.placeholder}
                required={field.required}
                onChange={valueChanged}
                order={field.order}
                values={selectValues}
                is_child={field.is_child}
                parent_id={field.parent_id}
                value={storedValues![field.id] || selectValues[0]}
              />
            );
          }

          return (
            <Fragment key={`contact_${field.id}_parent`}>
              <FormField
                key={`contact_child_${field.id}`}
                id={`contact_child_${field.id}`}
                name={field.id}
                fieldType={field.type}
                label={field.name}
                placeholder={field.placeholder}
                required={field.required}
                onChange={valueChanged}
                order={field.order}
                values={selectValues}
                is_child={field.is_child}
                parent_id={field.parent_id}
                value={storedValues![field.id] || selectValues[0]}
              />

              <FormFieldChildren
                storedValues={storedValues}
                key={`contact_${field.id}_children`}
                child_forms={selectOption.child_forms}
                selectedValue={storedValues![field.id] || selectValues[0]}
                valueChanged={valueChanged}
                order={field.order}
              />
            </Fragment>
          );
        }

        return (
          <FormField
            key={`contact_${field.id}`}
            id={`contact_${field.id}$`}
            name={field.id}
            fieldType={field.type}
            label={field.name}
            placeholder={field.placeholder}
            required={field.required}
            onChange={valueChanged}
            order={field.order}
            is_child={field.is_child}
            parent_id={field.parent_id}
            value={storedValues![field.id]}
          />
        );
      })}
    </>
  );
}
