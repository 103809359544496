import { ReactNode } from "react";
import { PartialProduct } from "@base-library/types";
export default function CartItemsFooter({
  group,
  product,
  add,
  subtract,
}: FooterProps): ReactNode {
  return (
    <section className="flex-1 flex items-end justify-between text-sm">
      <p className="text-white">${product.price.toPrecision()}</p>

      <article className="flex gap-2.5">
        {group.length > 1 && (
          <i
            className="text-white hover:cursor-pointer bi bi-dash-lg"
            onClick={(e) => {
              subtract(product);
              e.stopPropagation();
            }}
          ></i>
        )}
        <p className="text-gray-500">Qty {group.length}</p>
        <i
          className="text-white hover:cursor-pointer bi bi-plus-lg"
          onClick={(e) => {
            add(product);
            e.stopPropagation();
          }}
        ></i>
      </article>
    </section>
  );
}

interface FooterProps {
  group: PartialProduct[];
  product: PartialProduct;
  add: (product: PartialProduct) => void;
  subtract: (product: PartialProduct) => void;
}
