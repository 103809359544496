import { ReactNode } from "react";
import { AddonFieldType, IDictionary, ProductAddon } from "@base-library/types";
import {
  IAddonResult,
  MultiAddonResult,
  SliderAddonResult,
} from "@base-library/fields";

interface Props {
  index: number;
  selectedIndex?: number;
  results: IDictionary<IAddonResult>;
  addons: ProductAddon[];
}
interface MultiAddonContentProps {
  addon: ProductAddon;
  result: IAddonResult;
}

function MultiAddonContent({ addon, result }: MultiAddonContentProps) {
  const checkboxResult = result as MultiAddonResult;
  return (
    <section key={addon.id}>
      {checkboxResult.value.map((optionId) => {
        const foundOption = addon.options.find((x) => x.id === optionId);
        if (!foundOption) return;

        return (
          <p key={foundOption.id} className="text-green-500">
            +${foundOption.price} - {foundOption.name}
          </p>
        );
      })}
    </section>
  );
}

export default function AddonDropdown({
  index,
  selectedIndex,
  results,
  addons,
}: Props): ReactNode {
  if (selectedIndex !== index) return <></>;

  return (
    <article className="flex flex-col w-full text-white transition-all duration-500 ease-out">
      {Object.keys(results).map((addonId) => {
        const result = results[addonId];
        const addon = addons.find((x) => x.id === addonId);
        if (!addon) return;

        if (addon.field_type === AddonFieldType.Checkbox) {
          return (
            <MultiAddonContent addon={addon} result={result} key={addon.id} />
          );
        }

        const option = addon.options.find((b) => b.id === result.id);
        if (!option) return;

        if (addon.field_type === AddonFieldType.Slider) {
          const sliderResult = result as SliderAddonResult;

          return (
            <section key={addon.id}>
              {sliderResult.value} -
              <span
                className={option.reduce ? "text-red-500" : "text-green-500"}
              >
                {option.reduce ? `-` : `+`}${option.price * sliderResult.value}
              </span>
              - {addon.name}
            </section>
          );
        }

        return (
          <section key={addon.id}>
            <p className="text-green-500">
              +${option.price} - {option.name}
            </p>
          </section>
        );
      })}
    </article>
  );
}
