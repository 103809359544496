import { Button } from "@base-library/components/ui/button";
import MultiForm from "@base-library/components/forms/MultiForm";
import {
  IForm,
  FormSubmission,
  FormValues,
  IDictionary,
  AddonFieldType,
  ProductAddon,
} from "@base-library/types";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@base-library/components/ui/popover";
import { FormEvent } from "react";
import { SingleFormProduct } from "../types";
import ContactForm from "./ContactForm";
import {
  IAddonResult,
  MultiAddonResult,
  SliderAddonResult,
} from "@base-library/fields";

interface RenderFormProducts {
  forms: IForm[];
  singleFormProduct: SingleFormProduct;
  selectedAddons?: IDictionary<IAddonResult>;
  contactSubmit: (event: FormEvent<HTMLFormElement>) => Promise<void>;
  setInput: (id: string, value: string) => void;
  storedValues: FormValues;
  onFormSubmit: (
    success: boolean,
    formSubmission?: FormSubmission
  ) => Promise<void>;
}

interface ContentProps {
  selectedAddons: IDictionary<IAddonResult>;
  singleFormProduct: SingleFormProduct;
}

function getTotal(
  selectedAddons: IDictionary<IAddonResult>,
  addons: ProductAddon[]
) {
  let total = 0.0;

  for (const key of Object.keys(selectedAddons)) {
    const addon = addons.find((x) => x.id === key);

    if (!addon) continue;

    const result = selectedAddons[key];
    const option = addon.options.find((x) => x.id === result.id);

    if (!option) continue;

    if (addon.field_type === AddonFieldType.Slider) {
      const sliderResult = result as SliderAddonResult;

      total += sliderResult.value * option?.price;

      continue;
    } else if (addon.field_type === AddonFieldType.Checkbox) {
      const multiResult = result as MultiAddonResult;

      for (const optId of multiResult.value) {
        const opt = addon.options.find((x) => x.id === optId);

        total += opt!.price;
      }

      continue;
    }

    total += option.price;
  }

  return total;
}

interface MultiAddonContentProps {
  addon: ProductAddon;
  result: IAddonResult;
}

function MultiAddonContent({ addon, result }: MultiAddonContentProps) {
  const checkboxResult = result as MultiAddonResult;
  return (
    <section key={addon.id}>
      {checkboxResult.value.map((optionId) => {
        const foundOption = addon.options.find((x) => x.id === optionId);
        if (!foundOption) return;

        return (
          <p key={foundOption.id} className="text-green-500">
            +${foundOption.price} - {foundOption.name}
          </p>
        );
      })}
    </section>
  );
}

function AddonContent({ selectedAddons, singleFormProduct }: ContentProps) {
  return Object.keys(selectedAddons).map((addonId) => {
    const result = selectedAddons[addonId];
    const addon = singleFormProduct.product.addons!.find(
      (x) => x.id === addonId
    );
    if (!addon) return;

    if (addon.field_type === AddonFieldType.Checkbox) {
      return <MultiAddonContent key={addon.id} addon={addon} result={result} />;
    }

    const option = addon.options.find((b) => b.id === result.id);
    if (!option) return;

    if (addon.field_type === AddonFieldType.Slider) {
      const sliderResult = result as SliderAddonResult;

      return (
        <section key={addon.id}>
          <p>
            {sliderResult.value} -
            <span className={option.reduce ? "text-red-500" : "text-green-500"}>
              {option.reduce ? `-` : `+`}${option.price * sliderResult.value}
            </span>
            - {addon.name}
          </p>
        </section>
      );
    }

    return (
      <section key={addon.id}>
        <p className="text-green-500">
          +${option.price} - {option.name}
        </p>
      </section>
    );
  });
}

function calculateAddonCount(
  addons: ProductAddon[],
  selectedAddons: IDictionary<IAddonResult>
) {
  let count = Object.values(selectedAddons).length;

  for (const addonId of Object.keys(selectedAddons)) {
    const addon = addons.find((x) => x.id === addonId);
    if (!addon) continue;

    const result = selectedAddons[addonId];

    if (addon.field_type === AddonFieldType.Checkbox) {
      const checkboxResult = result as MultiAddonResult;

      count += checkboxResult.value.length - 1;
    }
  }

  return `${count} `;
}

export default function RenderForm({
  forms,
  singleFormProduct,
  contactSubmit,
  setInput,
  storedValues,
  onFormSubmit,
  selectedAddons,
}: RenderFormProducts) {
  if (forms.length === 0)
    return (
      <article className="mb-10 flex w-full flex-col gap-2">
        <section className="flex flex-col gap-1 text-center">
          <h1 className="text-white">{singleFormProduct?.product?.name}</h1>
          <h2 className="text-gray-600">
            {singleFormProduct?.product?.short_description}
          </h2>
          {selectedAddons && (
            <Popover>
              <PopoverTrigger>
                +
                {calculateAddonCount(
                  singleFormProduct.product.addons!,
                  selectedAddons
                )}
                addons - $
                {getTotal(selectedAddons, singleFormProduct.product.addons!)}
              </PopoverTrigger>
              <PopoverContent>
                <AddonContent
                  selectedAddons={selectedAddons}
                  singleFormProduct={singleFormProduct}
                />
              </PopoverContent>
            </Popover>
          )}
        </section>

        <section className="flex flex-col justify-center text-center rounded-md md:flex-row">
          <picture className="flex flex-col justify-center items-center p-6 lg:gap-12">
            <img
              id="product-image"
              className="w-full rounded-2xl sm:object-fill max-h-[560px]"
              src={singleFormProduct.product?.image_thumbnail}
            />
          </picture>

          <form
            className="flex flex-col gap-2.5 justify-between mt-2.5 min-h-full md:w-4/12"
            onSubmit={contactSubmit}
            autoComplete="off"
          >
            <section className="flex flex-col gap-2.5">
              <h1 className="text-white">Contact Information</h1>
              <ContactForm
                contact_form={singleFormProduct.contact_form}
                onUpdate={setInput}
                storedValues={storedValues}
              />
            </section>

            <Button id="submit" type="submit">
              Continue To Checkout
            </Button>
          </form>
        </section>
      </article>
    );

  return (
    <MultiForm onSubmit={onFormSubmit} allForms={forms} isCheckingOut={true}>
      {selectedAddons && (
        <Popover>
          <PopoverTrigger className="align-start">
            +
            {calculateAddonCount(
              singleFormProduct.product.addons!,
              selectedAddons
            )}
            addons - $
            {getTotal(selectedAddons, singleFormProduct.product.addons!)}
          </PopoverTrigger>
          <PopoverContent className="flex flex-col gap-2.5 dark">
            <AddonContent
              selectedAddons={selectedAddons}
              singleFormProduct={singleFormProduct}
            />
          </PopoverContent>
        </Popover>
      )}
      <article className="flex flex-col gap-2.5 mb-2.5">
        <h1 className="text-white">Contact Information</h1>
        <ContactForm
          contact_form={singleFormProduct.contact_form}
          onUpdate={setInput}
          storedValues={storedValues}
        />

        <div className="border-b border-gray-700"></div>
      </article>
    </MultiForm>
  );
}
