import { FormEvent, ReactNode, useEffect, useState } from "react";
import { FormSubmission, FormValues, IDictionary } from "@base-library/types";
import { IForm } from "@base-library/components/forms/MultiForm";
import Spinner from "@base-library/components/Spinner";
import { ErrorResponse, SingleFormProduct } from "../types";
import fastJson from "fast-json-stable-stringify";
import RenderForm from "./RenderForm";
import { IAddonResult } from "@base-library/fields";
import { decode } from "js-base64";
interface Props {
  singleFormProduct: SingleFormProduct;
  selectedAddons?: string;
}

enum Status {
  Default,
  Error,
  Forms,
}

type State = {
  userInfo: IDictionary<string>;
  status: Status;
  errorMessage?: string;
  submitting: boolean;
};

export default function SinglePageCheckout({
  singleFormProduct,
  selectedAddons,
}: Props): ReactNode {
  const [pageState, setPageState] = useState<State>({
    status: Status.Default,
    userInfo: {},
    submitting: false,
  });
  const [noContact] = useState<boolean>(singleFormProduct.form?.id === -1);
  const [storedValues, setStoredValues] = useState<FormValues>({});
  const [addons, _] = useState<IDictionary<IAddonResult>>(
    selectedAddons && JSON.parse(decode(selectedAddons))
  );

  const redirect = async () => {
    const resp = await fetch(`/api/cart/checkout`, {
      method: "POST",
      body: fastJson({
        contact_form: pageState.userInfo,
        product: singleFormProduct.product,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (resp.status >= 400) {
      window.location.pathname = singleFormProduct.product.slug;
      return;
    }

    window.location.href = (await resp.text()) as string;
  };

  useEffect(() => {
    if (addons) {
      Object.values(addons).forEach((result) => {
        const returned = Object.assign({ $t: result.$t }, result);

        result = returned;
      });
      singleFormProduct.product.selected_addons = addons;
    }

    if (noContact) {
      redirect();
    }
  }, []);

  const setInput = (id: string, value: string) => {
    const newValues = {
      ...storedValues,
    };
    newValues![id] = value;

    setStoredValues(newValues);

    setPageState({
      ...pageState,
      userInfo: {
        ...pageState.userInfo,
        [id]: value,
      },
    });
  };

  const contactSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.stopPropagation();
    event.preventDefault();

    setPageState({
      ...pageState,
      submitting: true,
    });

    const resp = await fetch(`/api/cart/checkout`, {
      method: "POST",
      body: fastJson({
        contact_form: pageState.userInfo,
        product: singleFormProduct.product,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (resp.status >= 400) {
      window.location.pathname = singleFormProduct.product.slug;
      return;
    }

    window.location.href = (await resp.text()) as string;
  };

  const onFormSubmit = async (
    success: boolean,
    formSubmission?: FormSubmission
  ) => {
    setPageState({
      ...pageState,
      submitting: true,
    });

    if (!success) {
      setPageState({
        ...pageState,
        status: Status.Error,
      });
      return;
    }

    let hasSubmission = true;
    if (!formSubmission?.submissions) {
      hasSubmission = false;
    }

    Object.values(formSubmission!.submissions!).forEach((item) => {
      if (!item.values) return;

      Object.values(item.values).forEach((result) => {
        const returned = Object.assign({ $t: result.$t }, result);

        result = returned;
      });
    });

    const resp = await fetch(`/api/cart/checkout`, {
      method: "POST",
      body: fastJson({
        submission: hasSubmission ? formSubmission!.submissions![0] : null,
        contact_form: pageState.userInfo,
        product: singleFormProduct.product,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (resp.status >= 400) {
      const error = (await resp.json()) as ErrorResponse;
      setPageState({
        ...pageState,
        status: Status.Error,
        errorMessage: error.detail,
      });
      return;
    }

    window.location.href = (await resp.text()) as string;
  };

  function getForms(): IForm[] {
    if (singleFormProduct.form)
      return [
        {
          fields: singleFormProduct.form.fields,
          id: singleFormProduct.form.id,
          index: 0,
          name: singleFormProduct.product.name,
          product: singleFormProduct.product,
        },
      ];

    return [];
  }

  const forms = getForms();

  if (noContact || pageState.submitting)
    return (
      <section className="flex grow items-center justify-center text-center text-white">
        <Spinner text={"Redirecting to checkout..."} />
      </section>
    );

  return (
    <section
      className="flex justify-center items-center p-8 pb-20 grow"
      data-permanent
    >
      {pageState.status === Status.Error && (
        <section
          className="absolute py-5 px-6 mb-4 text-base rounded-lg text-white"
          role="alert"
        >
          {pageState.errorMessage}
        </section>
      )}
      <RenderForm
        forms={forms}
        singleFormProduct={singleFormProduct}
        contactSubmit={contactSubmit}
        setInput={setInput}
        storedValues={storedValues}
        onFormSubmit={onFormSubmit}
        selectedAddons={addons}
      />
    </section>
  );
}
