import { FormEvent, ReactNode, useState } from "react";
import { Button } from "@base-library/components/ui/button";
import MultiForm from "@base-library/components/forms/MultiForm";
import {
  FormSubmission,
  CartForm,
  PartialProduct,
  IDictionary,
  FormValues,
} from "@base-library/types";
import {
  BaseFormField,
  MaskFormField,
  MultipleOptionsFormField,
  NumericFormField,
} from "@base-library/fields";
import Spinner from "@base-library/components/Spinner";
import CartImages from "./CartItems";
import fastJson from "fast-json-stable-stringify";
import { ErrorResponse, UpdateResponse } from "../types";
import ContactForm from "./ContactForm";

interface Props {
  cartForms: CartForm[];
  products: PartialProduct[];
  hasForms: boolean;
  onUpdate: (updateResponse?: UpdateResponse) => Promise<void> | void;
  contact_form: IDictionary<
    BaseFormField | MaskFormField | NumericFormField | MultipleOptionsFormField
  >;
}

enum Status {
  Default,
  Error,
  Forms,
}

type State = {
  userInfo: IDictionary<string>;
  status: Status;
  errorMessage?: string;
  submitting: boolean;
};

export default function Cart({
  cartForms,
  products,
  hasForms,
  onUpdate,
  contact_form,
}: Props): ReactNode {
  const [pageState, setPageState] = useState<State>({
    status: Status.Default,
    userInfo: {},
    submitting: false,
  });
  const [storedContactInfo, setStoredContactInfo] = useState<FormValues>({});

  const hasCartForms =
    cartForms && cartForms!.filter((item) => item.form_id !== -1).length > 0;

  const dataLength =
    (cartForms && cartForms.length) || (products && products.length) || 0;

  const allProducts =
    cartForms.length > 0 ? cartForms.map((x) => x.product) : products;

  const forms = !hasForms ? (
    <p className="p-6 text-center">No Forms To Complete</p>
  ) : (
    <MultiForm
      onSubmit={onFormSubmit}
      allForms={cartForms!
        .filter((item) => item.form_id !== -1)
        .map((form, index) => ({
          fields: form.fields,
          id: form.form_id,
          index: index,
          name: form.product.name,
          product: form.product,
        }))}
      isCheckingOut={true}
    />
  );

  let cartTotal = allProducts.reduce((sum, current) => sum + current.price, 0);

  const formattedTotal = new Intl.NumberFormat(undefined, {
    style: "currency",
    currency: "USD",
    currencyDisplay: "narrowSymbol",
  }).format(cartTotal);
  function setInput(id: string, value: string) {
    const newValues = {
      ...storedContactInfo,
    };

    newValues[id] = value;

    setStoredContactInfo(newValues);

    setPageState({
      ...pageState,
      userInfo: {
        ...pageState.userInfo,
        [id]: value,
      },
    });
  }

  async function submit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    event.stopPropagation();

    const cart = sessionStorage.getItem("cart") || "";
    if (hasCartForms) {
      setPageState({
        ...pageState,
        status: Status.Forms,
      });

      return;
    }

    setPageState({
      ...pageState,
      submitting: true,
    });

    const resp = await fetch(`/api/cart/process`, {
      method: "POST",
      body: fastJson({
        contact_form: pageState.userInfo,
        is_checking_out: true,
        cart_id: cart,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (resp.status >= 400) {
      const error = (await resp.json()) as ErrorResponse;
      setPageState({
        ...pageState,
        status: Status.Error,
        errorMessage: error.detail,
      });

      setTimeout(() => {
        setPageState({
          ...pageState,
          status: Status.Default,
        });
      }, 2000);

      return;
    }

    window.location.href = (await resp.text()) as string;
  }

  async function onFormSubmit(
    success: boolean,
    formSubmission?: FormSubmission
  ) {
    const cart = sessionStorage.getItem("cart") || "";

    if (!success) {
      setPageState({
        ...pageState,
        status: Status.Error,
      });
      return;
    }

    Object.values(formSubmission!.submissions)!.forEach((item) => {
      if (!item.values) return;

      Object.values(item.values).forEach((result) => {
        const returned = Object.assign({ $t: result.$t }, result);

        result = returned;
      });
    });

    const resp = await fetch(`/api/cart/process`, {
      method: "POST",
      body: fastJson({
        ...formSubmission,
        contact_form: pageState.userInfo,
        is_checking_out: true,
        cart_id: cart,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (resp.status >= 400) {
      const problem = (await resp.json()) as ErrorResponse;
      onUpdate({ message: problem.detail });
      return;
    }

    window.location.href = (await resp.text()) as string;
  }

  function onItemsUpdate(id: string) {
    onUpdate({ product_id: id });
  }

  return (
    <section
      className="flex justify-center items-center p-8 pb-20 bg-background w-full"
      data-permanent
    >
      {pageState.status === Status.Forms ? (
        forms
      ) : (
        <article className="flex relative flex-col justify-between w-full rounded-lg border bg-card text-card-foreground shadow-sm md:flex-row md:w-5/6  md:h-[560px]">
          {pageState.status === Status.Error && (
            <section
              className="absolute py-5 px-6 mb-4 text-base rounded-lg bg-neutral-800 text-neutral-50 dark:bg-neutral-900"
              role="alert"
            >
              {pageState.errorMessage}
            </section>
          )}

          <section className="p-4 md:w-10/12">
            <article className="flex justify-between mb-8 w-full">
              <h1 className="text-xl text-white">Your Cart</h1>
              <h1 className="text-xl text-white">
                {dataLength > 1 ? `${dataLength} Items` : `1 Item`}
              </h1>
            </article>

            <section className="flex overflow-y-auto flex-col gap-2.5 py-4 border-t border-gray-700 max-h-[475px]">
              <CartImages
                cartForms={cartForms}
                products={products}
                onUpdate={onItemsUpdate}
              />
            </section>
          </section>

          <section className="flex flex-col gap-2.5 justify-between p-4 bg-gradient-to-b to-gray-900 border-t border-gray-700 md:w-5/12 md:border-t-0 md:border-l from-gray">
            <article className="flex flex-col gap-2 divide-y divide-gray-700">
              <article className="flex justify-between mb-6 w-full">
                <h1 className="text-xl text-white">Order Summary</h1>
              </article>

              <section className="flex justify-between py-3 text-gray-400">
                <p>Subtotal</p>
                <p id="cart-total">USD {formattedTotal}</p>
              </section>

              {/*<section className="flex justify-between py-3 mb-0 text-gray-400">*/}
              {/*    <p>Taxes</p>*/}
              {/*    <p id="cart-total">*/}
              {/*        $0*/}
              {/*    </p>*/}
              {/*</section>*/}

              <section className="flex justify-between py-3 text-gray-400">
                <p>Total</p>
                <p id="cart-total">USD {formattedTotal}</p>
              </section>
            </article>

            <form
              className="flex flex-col gap-2.5 mb-2.5"
              onSubmit={submit}
              autoComplete="off"
            >
              <h1 className="text-white">Contact Information</h1>
              <ContactForm
                contact_form={contact_form}
                onUpdate={setInput}
                storedValues={storedContactInfo}
              />

              <FormFooter hasCartForms={hasCartForms} state={pageState} />
            </form>
          </section>
        </article>
      )}
    </section>
  );
}

function FormFooter({
  state,
  hasCartForms,
}: {
  state: State;
  hasCartForms: boolean;
}): ReactNode {
  if (state.submitting) return <Spinner />;

  return (
    <Button type="submit">
      {hasCartForms ? "Continue" : "Continue To Checkout"}
    </Button>
  );
}
