import { PartialProduct } from "@base-library/types";

export default function useCartItems(
  onUpdate?: (id: string) => Promise<void> | void
) {
  async function removeItem(product: PartialProduct) {
    const cart = sessionStorage.getItem("cart") || "";
    const resp = await fetch("/api/cart/", {
      method: "DELETE",
      body: JSON.stringify({
        cart_id: cart,
        item_id: product.item_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (resp.ok && onUpdate) await onUpdate(product.item_id);
  }

  async function add(product: PartialProduct) {
    const cart = sessionStorage.getItem("cart") || "";
    const resp = await fetch("/api/cart/", {
      method: "post",
      body: JSON.stringify({
        cart_id: cart,
        item_id: product.item_id,
        child_index: product.child_index,
        product_slug: product.slug,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (resp.ok && onUpdate) await onUpdate(product.item_id);
  }

  async function subtract(product: PartialProduct) {
    const cart = sessionStorage.getItem("cart") || "";
    const resp = await fetch("/api/cart/", {
      method: "DELETE",
      body: JSON.stringify({
        cart_id: cart,
        item_id: product.item_id,
        remove_one: true,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (resp.ok && onUpdate) await onUpdate(product.item_id);
  }

  return [removeItem, add, subtract];
}
